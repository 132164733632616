export const projectName = 'SitoBank';

export function getApiDomain() {
  let hostname = getDomain(location.hostname);
  let protocol = location.protocol;
  switch (process.env.NODE_ENV) {
    case 'dev':
    case 'development':
      hostname = 'payment-dev.com';
      protocol = 'https:';
      break;
  }
  let domain = `${protocol}//api.${hostname}`;
  return domain;
}

export function getWssDomain() {
  let hostname = getDomain(location.hostname);
  let protocol = 'wss:';
  switch (process.env.NODE_ENV) {
    case 'dev':
    case 'development':
      hostname = 'payment-dev.com';
      break;
  }
  let domain = `${protocol}//wss.${hostname}`;
  return domain;
}

export function getExqueryDomain() {
  let hostname = getDomain(location.hostname);
  let protocol = location.protocol;
  switch (process.env.NODE_ENV) {
    case 'dev':
    case 'development':
      hostname = 'payment-dev.com';
      protocol = 'https:';
      break;
  }
  let domain = `https://exquery.sitobank.com`;
  return domain;
}

function getDomain(hostname) {
  return hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1);
}

export function getOrigin() {
  let hostname = getDomain(location.hostname);
  let protocol = location.protocol;
  switch (process.env.NODE_ENV) {
    case 'dev':
      hostname = 'sitobank.com';
    case 'development':
      hostname = 'payment-dev.com';
      protocol = 'https:';
      break;
  }
  let domain = `${protocol}//manager.${hostname}`;
  return domain;
}
